.layout {
  padding: 20px 40px 0;
  display: flex;
  flex-direction: column;
}
.body {
  display: flex;
  justify-content: center;
  gap: 75px;
  color: white;
  padding-top: 65px;
}

@media screen and (max-width: 800px) {
  .body {
    flex-direction: column;
  }
}
@media screen and (max-width: 650px) {
  .layout {
    padding: 20px 20px 0;
  }
}
