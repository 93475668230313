:root {
  --hoverRes: 800px;
}
body {
  background-color: #0f172a;
  overflow-x: hidden;
  padding-bottom: 20px;
}

@media screen and (min-width: 800px) {
  body {
    height: 100vh;
    background-color: #0f172a;
    overflow: hidden;
  }
  body::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: calc(var(--y, 0) * 1px - var(--hoverRes) / 2);
    left: calc(var(--x, 0) * 1px - var(--hoverRes) / 2);
    width: var(--hoverRes);
    height: var(--hoverRes);
    background: radial-gradient(rgba(0, 95, 248, 0.144), #ffffff00 70%);
  }
}
